<template>
  <el-dialog v-model="dialogVisible" title="添加时段" width="700px" :before-close="handleClose">
    <el-form ref="formRef" :model="form" :rules="rules" label-width="100px" style="padding: 20px">
      <el-form-item label="时段" prop="timeRange" class="time-range-item is-required">
        <div class="time-range">
          <el-select
            v-model="form.startTime"
            placeholder="选择时间"
            style="width: 200px"
            @change="handleStartTimeChange"
            clearable
            @clear="handleStartTimeClear"
          >
            <el-option v-for="time in startTimeOptions" :key="time.label" :label="time.label" :value="time.label" />
          </el-select>
          <span class="separator">~</span>
          <el-select
            v-model="form.endTime"
            placeholder="选择时间"
            style="width: 200px"
            @change="handleEndTimeChange"
            clearable
            @clear="handleEndTimeClear"
          >
            <el-option v-for="time in endTimeOptions" :key="time.label" :label="time.label" :value="time.label" />
          </el-select>
        </div>
      </el-form-item>

      <el-form-item label="开放渠道" prop="channels">
        <el-checkbox-group v-model="form.channels">
          <el-checkbox :label="0">小程序</el-checkbox>
          <el-checkbox :label="1" disabled>POS收银</el-checkbox>
          <el-checkbox :label="2" disabled>抖音(暂不可选)</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, defineComponent, reactive, watch, computed } from 'vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'AddTimeSlotDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'success'],
  setup(props, { emit }) {
    const formRef = ref(null)
    const dialogVisible = ref(props.modelValue)

    // 生成时间选项
    const generateTimeOptions = () => {
      const options = []
      let currentHour = 8
      let currentMinute = 0
      let isNextDay = false

      while (true) {
        const hour = currentHour.toString().padStart(2, '0')
        const minute = currentMinute.toString().padStart(2, '0')
        const timeStr = `${hour}:${minute}`
        const label = isNextDay ? `次日${timeStr}` : timeStr

        options.push({
          label,
          value: timeStr,
          isNextDay,
          timestamp: (isNextDay ? 24 * 60 : 0) + currentHour * 60 + currentMinute, // 用于比较时间大小
        })

        // 增加30分钟
        currentMinute += 30
        if (currentMinute === 60) {
          currentMinute = 0
          currentHour++
          if (currentHour === 24) {
            currentHour = 0
            isNextDay = true
          }
        }

        // 到达次日8:00后结束
        if (isNextDay && currentHour === 8 && currentMinute === 0) {
          options.push({
            label: `次日08:00`,
            value: '08:00',
            isNextDay: true,
            timestamp: 24 * 60 + 8 * 60,
          })
          break
        }
      }
      return options
    }

    const timeOptions = generateTimeOptions()

    // 表单数据
    const form = reactive({
      startTime: null,
      endTime: null,
      channels: [0],
    })

    // 表单校验规则
    const rules = {
      startTime: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
      endTime: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
      channels: [{ required: true, type: 'array', min: 1, message: '请至少选择一个开放渠道', trigger: 'change' }],
    }

    // 当开始时间改变时，如果结束时间小于等于开始时间，清空结束时间
    const handleStartTimeChange = () => {
      if (!form.endTime) return
      const startOption = timeOptions.find(option => option.label === form.startTime)
      const endOption = timeOptions.find(option => option.label === form.endTime)
      if (startOption && endOption && startOption.timestamp >= endOption.timestamp) {
        form.endTime = null
      }
    }

    // 当结束时间改变时，如果开始时间大于等于结束时间，清空开始时间
    const handleEndTimeChange = () => {
      if (!form.startTime) return
      const startOption = timeOptions.find(option => option.label === form.startTime)
      const endOption = timeOptions.find(option => option.label === form.endTime)
      if (startOption && endOption && startOption.timestamp >= endOption.timestamp) {
        form.startTime = null
      }
    }

    // 计算开始时间选项
    const startTimeOptions = computed(() => {
      if (!form.endTime) return timeOptions

      const endOption = timeOptions.find(option => option.label === form.endTime)
      if (!endOption) return timeOptions

      return timeOptions.filter(option => option.timestamp < endOption.timestamp)
    })

    // 计算结束时间选项
    const endTimeOptions = computed(() => {
      if (!form.startTime) return timeOptions

      const startOption = timeOptions.find(option => option.label === form.startTime)
      if (!startOption) return timeOptions

      return timeOptions.filter(option => option.timestamp > startOption.timestamp)
    })

    // 监听弹窗显示状态
    watch(
      () => props.modelValue,
      val => {
        dialogVisible.value = val
      },
    )

    watch(
      () => dialogVisible.value,
      val => {
        emit('update:modelValue', val)
        if (!val) {
          // 重置表单
          form.startTime = null
          form.endTime = null
          form.channels = []
          formRef.value?.resetFields()
        }
      },
    )

    // 监听时间变化，确保只能选择整点或半点
    watch(
      () => form.startTime,
      val => {
        if (val) {
          const minutes = parseInt(val.split(':')[1])
          if (minutes !== 0 && minutes !== 30) {
            const hour = val.split(':')[0]
            form.startTime = `${hour}:${minutes < 30 ? '00' : '30'}`
          }
        }
      },
    )

    watch(
      () => form.endTime,
      val => {
        if (val) {
          const minutes = parseInt(val.split(':')[1])
          if (minutes !== 0 && minutes !== 30) {
            const hour = val.split(':')[0]
            form.endTime = `${hour}:${minutes < 30 ? '00' : '30'}`
          }
        }
      },
    )

    // 当开始时间被清除时
    const handleStartTimeClear = () => {
      form.startTime = null
    }

    // 当结束时间被清除时
    const handleEndTimeClear = () => {
      form.endTime = null
    }

    // 关闭弹窗
    const handleClose = () => {
      dialogVisible.value = false
    }

    // 提交表单
    const handleSubmit = async () => {
      if (!formRef.value) return

      await formRef.value.validate((valid, fields) => {
        try {
          if (valid) {
            if (form.startTime === null || form.endTime === null) {
              ElMessage.error('请选择开始和结束时间')
              return
            }
            // 验证时间范围
            if (form.startTime >= form.endTime) {
              ElMessage.error('结束时间必须大于开始时间')
              return
            }

            emit('success', {
              startTime: form.startTime,
              endTime: form.endTime,
              channels: form.channels,
            })

            dialogVisible.value = false
          }
        } catch (error) {
          console.log(error)
        }
      })
    }

    return {
      dialogVisible,
      form,
      formRef,
      rules,
      handleClose,
      handleSubmit,
      timeOptions,
      startTimeOptions,
      endTimeOptions,
      handleStartTimeChange,
      handleEndTimeChange,
      handleStartTimeClear,
      handleEndTimeClear,
    }
  },
})
</script>

<style lang="scss" scoped>
.time-range-item {
  .time-range {
    display: flex;
    align-items: center;
    gap: 10px;

    .separator {
      color: #606266;
    }

    :deep(.el-select) {
      .el-input__wrapper {
        width: 120px;
      }
    }
  }

  .time-tip {
    font-size: 12px;
    color: #909399;
    margin-top: 5px;
  }
}

:deep(.el-checkbox.is-disabled) {
  .el-checkbox__label {
    color: #909399;
  }
}
</style>
